import { Routes } from '@angular/router';

import { BlankComponent, FullComponent } from '@app/shared';

export const AppRoutes: Routes = [
    // {
    //     path: '',
    //     component: FullComponent,
    //     children: [
    //         // Default
    //         // {
    //         //     path: '',
    //         //     redirectTo: '/home',
    //         //     pathMatch: 'full'
    //         // },
    //         {
    //             path: 'dashboard',
    //             loadChildren: () =>
    //                 import('./dashboard/dashboard.module').then(m => m.DashboardModule)
    //         }
    //     ]
    // },
    //   {
    //     path: '',
    //     component: BlankComponent,
    //     children: [
    //       {
    //         path: '',
    //         loadChildren: () =>
    //           import('./authentication/authentication.module').then(
    //             m => m.AuthenticationModule
    //           )
    //       }
    //     ]
    //   },

    // {
    //     path: '**',
    //     redirectTo: '/404'
    // }
];
