import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonLoadingComponent } from './button-loading.component';
import { ButtonLoadingDirective } from './button-loading.directive';



@NgModule({
    declarations: [
        ButtonLoadingComponent,
        ButtonLoadingDirective,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        ButtonLoadingComponent,
        ButtonLoadingDirective,
    ],
    entryComponents: [
        ButtonLoadingComponent,
    ]
})
export class ButtonLoadingModule { }