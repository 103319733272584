<!-- Footer -->
<section id="footer" class="bg-dark">
    <div class="container">
        <div class="row text-center text-xs-center text-sm-left text-md-left">
            <div class="col-xs-12 col-sm-4 col-md-4">
                <!-- <h5>Quick links</h5>
                <ul class="list-unstyled quick-links">
                    <li class="my-1"><a href="#"><i class="fa fa-angle-double-right mr-2"></i>Home</a>
                    </li>
                    <li class="my-1"><a href="#"><i class="fa fa-angle-double-right mr-2"></i>About</a>
                    </li>
                    <li class="my-1"><a href="#"><i class="fa fa-angle-double-right mr-2"></i>FAQ</a>
                    </li>
                    <li class="my-1"><a href="#"><i class="fa fa-angle-double-right mr-2"></i>Get
                            Started</a></li>
                    <li class="my-1"><a href="#"><i class="fa fa-angle-double-right mr-2"></i>Videos</a>
                    </li>
                </ul> -->
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4">
                <!-- <h5>Quick links</h5>
                <ul class="list-unstyled quick-links">
                    <li class="my-1"><a href="#"><i class="fa fa-angle-double-right mr-2"></i>Home</a>
                    </li>
                    <li class="my-1"><a href="#"><i class="fa fa-angle-double-right mr-2"></i>About</a>
                    </li>
                    <li class="my-1"><a href="#"><i class="fa fa-angle-double-right mr-2"></i>FAQ</a>
                    </li>
                    <li class="my-1"><a href="#"><i class="fa fa-angle-double-right mr-2"></i>Get
                            Started</a></li>
                    <li class="my-1"><a href="#"><i class="fa fa-angle-double-right mr-2"></i>Videos</a>
                    </li>
                </ul> -->
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4">
                <!-- <h5>Quick links</h5>
                <ul class="list-unstyled quick-links">
                    <li class="my-1"><a href="#"><i class="fa fa-angle-double-right mr-2"></i>Home</a>
                    </li>
                    <li class="my-1"><a href="#"><i class="fa fa-angle-double-right mr-2"></i>About</a>
                    </li>
                    <li class="my-1"><a href="#"><i class="fa fa-angle-double-right mr-2"></i>FAQ</a>
                    </li>
                    <li class="my-1"><a href="#"><i class="fa fa-angle-double-right mr-2"></i>Get
                            Started</a></li>
                    <li class="my-1"><a href="#"><i class="fa fa-angle-double-right mr-2"></i>Videos</a>
                    </li>
                </ul> -->
            </div>
        </div>
        <div class="row">
            <!-- <div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
                <ul class="list-unstyled quick-links">
                    <li class="my-1"><a href="#"><i class="fa fa-angle-double-right mr-2"></i>Home</a>
                    </li>
                    <li class="my-1"><a href="#"><i class="fa fa-angle-double-right mr-2"></i>About</a>
                    </li>
                    <li class="my-1"><a href="#"><i class="fa fa-angle-double-right mr-2"></i>FAQ</a>
                    </li>
                    <li class="my-1"><a href="#"><i class="fa fa-angle-double-right mr-2"></i>Get
                            Started</a></li>
                    <li class="my-1"><a href="#"><i class="fa fa-angle-double-right mr-2"></i>Videos</a>
                    </li>
                </ul>
            </div> -->
            <!-- <hr /> -->
        </div>
        <div class="row py-4">
            <div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
                <p class="h6 text-white">© NotifyPetsAtPeace.com. All right Reversed.</p>
            </div>
            <!-- <hr /> -->
        </div>
    </div>
</section>
<!-- ./Footer -->