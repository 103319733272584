import * as $ from 'jquery';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ShowdownModule } from 'ngx-showdown';

import { AppRoutes } from '@app/app.routing';
import { AuthenticationModule } from './authentication/authentication.module';
import { CoreModule } from '@app/core';
import { DashboardModule } from './dashboard/dashboard.module';
import { SharedModule } from '@app/shared';
import { SurveyModule } from './survey/survey.module';

import { AppComponent } from '@app/app.component';
import { AnalyticComponent } from './_directives/analytic/analytic.component';
import { NoDecimalPipe } from './_pipes/no-decimal.pipe';

@NgModule({
    declarations: [
        AppComponent,

        AnalyticComponent,
        NoDecimalPipe,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        CoreModule,
        HttpClientModule,
        RouterModule.forRoot(AppRoutes),

        NgbModule,
        ShowdownModule,

        AuthenticationModule,
        DashboardModule,
        SharedModule,
        SurveyModule,
    ],
    providers: [
        NoDecimalPipe, // THis allows the pipe to be used in code
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
