import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@app/core/local-storage.service';
import { ErrorMessageService } from '@app/core/service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

const credentialsKey = 'credentials';

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private localStorageService: LocalStorageService,
        private errorMessageService: ErrorMessageService,
        private toastrService: ToastrService
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next
            .handle(request)
            .pipe(catchError(error => this.errorHandler(error)));
    }

    // Customize the default error handler here if needed
    private errorHandler(
        response: HttpResponse<any>
    ): Observable<HttpEvent<any>> {
        if (response.status === 401) {
            this.localStorageService.clearItem(credentialsKey);
            this.router.navigate(['/login'], {
                replaceUrl: true
            });
        } else if (response.status === 400) {
            const errorResponse: any = response;
            if (errorResponse.error) {
                if (errorResponse.error.validation) {
                    errorResponse.error.validation.keys.forEach((key: string) => {
                        this.errorMessageService.set(
                            errorResponse.error.validation.errors[key],
                            key,
                            response.url
                        );
                    });
                } else {
                    this.errorMessageService.set(
                        errorResponse.error.error,
                        '_GLOBAL_',
                        response.url
                    );
                }
            }
        }

        if (!environment.production) {
            // Do something with the error
            console.error('Request error', response);
        }

        // Either dev or prodution
        const r: any = response; // To get away from Property 'message' does not exist on type 'HttpResponse<any>'. error

        /* ======================================================
            Look for a pretty error message first
    
            {
              "headers": {
                "normalizedNames": {},
                "lazyUpdate": null
              },
              "status": 409,
              "statusText": "Conflict",
              "url": "http://localhost:3000/login",
              "ok": false,
              "name": "HttpErrorResponse",
              "message": "Http failure response for http://localhost:3000/login: 409 Conflict",
              "error": {
                "errors": {
                  "msg": "Incorrect password"
                }
              }
            }
            ====================================================== */

        const errorMessage =
            r.error && r.error.errors && r.error.errors.msg
                ? r.error.errors.msg
                : r.message;

        if (typeof errorMessage === 'object' && errorMessage.length > 0) {
            for (let i = 0; i < errorMessage.length; i++) {
                this.toastrService.error(
                    errorMessage[i].msg,
                    'Error'
                    // , { disableTimeOut: true }
                );
            }
        } else {
            this.toastrService.error(
                errorMessage,
                'Error'
                // , { disableTimeOut: true }
            );
        }

        throw response;
    }
}
