import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';

import { ButtonLoadingModule } from './../modules/button-loading/button-loading.module';
import { StripeModule } from './../stripe/stripe.module';

import { BlankComponent } from '@app/shared/layouts/blank/blank.component';
import { BreadcrumbComponent } from '@app/shared/breadcrumb/breadcrumb.component';
import { ButtonLoaderComponent } from '@app/shared/button-loader';
import { FooterComponent } from './layouts/partials/footer/footer.component';
import { FormErrorWrapperComponent } from '@app/shared/form-error-wrapper/form-error-wrapper.component';
import { FullComponent } from '@app/shared/layouts/full/full.component';
import { HeaderComponent } from './layouts/partials/header/header.component';
import { SpinnerComponent } from '@app/shared/spinner.component';


@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        RouterModule,
        NgbModule,
        ReactiveFormsModule,
        ToastrModule.forRoot({
            progressBar: true
        }),

        ButtonLoadingModule,
        StripeModule,
    ],
    declarations: [
        BlankComponent,
        BreadcrumbComponent,
        ButtonLoaderComponent,
        FooterComponent,
        FormErrorWrapperComponent,
        FullComponent,
        HeaderComponent,
        SpinnerComponent,
    ],
    exports: [
        BlankComponent,
        BreadcrumbComponent,
        ButtonLoaderComponent,
        ButtonLoadingModule,
        FullComponent,
        FormErrorWrapperComponent,
        SpinnerComponent,

        StripeModule,
    ],
    entryComponents: [],
    providers: []
})
export class SharedModule { }
