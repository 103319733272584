import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'noDecimal'
})
export class NoDecimalPipe implements PipeTransform {

    transform(value, args?) {
        return value.toString().replace(/\./g, '');
    }

}
