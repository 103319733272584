import {
    ComponentRef,
    ComponentFactoryResolver,
    Directive,
    Input,
    OnDestroy,
    ViewContainerRef,
    OnInit
} from '@angular/core';

import { ButtonLoadingComponent } from './button-loading.component';

@Directive({
  selector: '[appButtonLoading]'
})
export class ButtonLoadingDirective implements OnDestroy, OnInit {
    /* ======================================================
    This @Input is the same name as the directive!  That way there's no second property we need to get the input
    We'll also use .loading on the component for *ngIf - only seem to need @Input for tslint? It seems to work just setting this property
    ====================================================== */

    /* ======================================================
    <button type="submit" [appButtonLoading]="loginLoading" class="btn btn-primary icon-btn" appAnalytic="Lock {{user.CSCEntityID}}-Log In"><i
                    class="fa fa-arrow-right" *ngIf="!loginLoading"></i></button>
    ====================================================== */

    private _ColorOverride: string = '';

    @Input()
    set appButtonLoading(loading: boolean) {
        this.toggleLoader(loading);
    }
    @Input() ColorOverride: string = '';

    private componentInstance: ComponentRef<ButtonLoadingComponent> = null;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver,
    ) { }



    toggleLoader(loading: boolean) {
        if (!this.componentInstance) {
            this.createLoaderComponent();
            this.makeComponentAChild();
        }

        // When using this as a loading button - like when the page loads
        // and the button text is loading (or something), set the loading
        // value to true - in other cases it won't matter
        this.componentInstance.instance.loading = loading;
    }



    private createLoaderComponent() {
        // Try to add disabled?  Couldn't get it to work
        // console.log( loaderComponentElement.parentElement, loaderComponentElement.parentElement.attributes, loaderComponentElement.parentElement.disabled = 'disabled' );
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ButtonLoadingComponent);
        this.componentInstance = this.viewContainerRef.createComponent(componentFactory);

        // console.log('createLoaderComponent', componentFactory, 'this.componentInstance', this.componentInstance,
        //   this.componentInstance.instance, this.componentInstance.instance.loading,
        //   'abc123', componentFactory.inputs);
    }



    private makeComponentAChild() {
        const loaderComponentElement = this.componentInstance.location.nativeElement;
        const sibling: HTMLElement = loaderComponentElement.previousSibling;
        sibling.insertBefore(loaderComponentElement, sibling.firstChild);
    }



    ngOnDestroy(): void {
        if (this.componentInstance)
            this.componentInstance.destroy();
    }



    ngOnInit(): void {
        this.componentInstance.instance.ColorOverride = this.ColorOverride; // It wouldn't work if I tried setting this where I'm setting .loading
    }

    

}