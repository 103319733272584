import { formatCurrency } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { SurveyService } from './../survey.service';
import { UtilitiesService } from './../../_services/utilities.service';

@Component({
    selector: 'app-page-survey',
    templateUrl: './page-survey.component.html',
    styleUrls: ['./page-survey.component.scss']
})
export class PageSurveyComponent implements OnInit {

    public ClientAnswers: any = {};
    public Finished = false;
    public LoadingSurvey = false;
    public OrderPricingData: any = {};
    public PaymentSuccess = false;
    public QuestionnaireID = '';
    public SelectedProductsServices = {};
    public ShowStripe = false;
    public ShowSurvey = false;
    public SurveyJSON: any = {};
    public Subtotal: number = 0;
    public TotalPriceWithTax: number = 0;
    public TotalTax: number = 0;

    constructor(
        private SurveyService: SurveyService,
        private UtilitiesService: UtilitiesService,
    ) { }



    private AddPricesToData(currentPage) {
        /* ======================================================
        Remove any previously set value - really only an issue on
        page 1 with the dynamic cremation pricing
        ====================================================== */
        document.querySelectorAll('.price-placeholder').forEach(e => e.parentNode.removeChild(e));

        for (let page = 0; page < this.SurveyJSON.pages.length; page++) {

            /* ======================================================
            Let's only do the replacement stuff on the current page because
            we need to use document.querySelector - haven't been able to do the model dynamically
            ====================================================== */
            if (page !== currentPage)
                continue;

            for (let q = 0; q < this.SurveyJSON.pages[page].elements.length; q++) {
                const question = this.SurveyJSON.pages[page].elements[q];

                /* ======================================================
                Check if there is pricing for this question
                ====================================================== */

                if (this.PriceLookup[question.name]) {

                    for (let c = 0; c < question.choices.length; c++) {
                        const questionAnswer = question.choices[c].value;
                        const questionPriceInfo = this.PriceLookup[question.name][questionAnswer];
                        const hasWeightPricing = (typeof questionPriceInfo === 'object');
                        let displayPrice = 0;
                        let weightPrices;

                        if (hasWeightPricing) {
                            // Assume we have the pet weight from page 1
                            const petWeight = parseFloat(this.ClientAnswers['pets_weight']);
                            weightPrices = questionPriceInfo.weightPrices;

                            for (let i = 0; i < weightPrices.length; i++) {
                                let currentPriceParameters = weightPrices[i];

                                if (petWeight >= currentPriceParameters.minWeight && petWeight <= currentPriceParameters.maxWeight) {
                                    const productPrice = currentPriceParameters.price;
                                    displayPrice = productPrice;
                                }
                            }

                        } else {
                            displayPrice = questionPriceInfo;
                        }

                        // if (displayPrice > 0) { // Included / standard will be $0 - still display that

                        let priceElement = document.createElement('div');
                        priceElement.classList.add('price-placeholder');
                        if (typeof displayPrice === 'number') {
                            priceElement.innerHTML = formatCurrency(displayPrice, 'EN', '$');
                        } else if (typeof displayPrice === 'string') { // When it's not available - show that text
                            // Keep the "Not Available" text so I can evaluate and get to this block
                            const maxAllowedWeight = weightPrices.slice(-2, -1)[0].maxWeight; // Get the second to last array (the one before this "not available" option) which will have the maxWeight we want
                            priceElement.innerHTML = '<small>' + displayPrice + maxAllowedWeight + ' pounds.</small>';
                            document.querySelector('[name="' + question.name + '"] [value="' + questionAnswer + '"]').setAttribute('disabled', 'disabled'); // Visible but not selectable
                            // document.querySelector('[name="' + question.name + '"] [value="' + questionAnswer + '"]').parentNode.parentNode.classList.add('d-none'); //div.sv_q_radiogroup > label.custom-control.custom-radio > input[type="radio"] -  Hidden
                        }

                        let currentAnswerHTML = document.querySelector('[name="' + question.name + '"] [value="' + questionAnswer + '"]').parentNode;
                        currentAnswerHTML.appendChild(priceElement)

                        // Trying to update the model after it had been "registered" didn't work
                        // console.log(this.SurveyJSON.pages[page].elements[q].choices[c].text)
                        // Append to description
                        // this.SurveyJSON.pages[page].elements[q].choices[c].text += '<div class="price">' + formatCurrency(displayPrice, 'EN', '$') + '</div>'
                        // }

                    }

                }

            }

        }
    }



    // public CalculateMatrixRowTotal(matrixRow, addKeyValues) {

    //     /* ======================================================
    //     We'll get an object like
    //     {
    //         'Classic Pet': {
    //             Add: ['Add'],
    //             Size: 'Small',
    //             Quantity: 2,
    //         },
    //     }
    //     ====================================================== */

    //     Object.keys(matrixRow).forEach(productName => {
    //         console.log(productName);

    //         /* ======================================================
    //         Find product name in the lookup table
    //         ====================================================== */
    //         if (this.PriceLookup[productName]) {

    //             /* ======================================================
    //             addKeyValues are also considered required fields - let's
    //             make sure we have them all before we try to add
    //             ====================================================== */
    //             // for (let i = 0; i < addKeyValues.length; i++) {

    //             //     if (matrixRow[productName].hasOwnProperty(addKeyValues[i]) === false)
    //             //         return false; // Not ready

    //             // }

    //             /* ======================================================
    //             Now we know we have all the properties, let's add them
    //             ====================================================== */
    //             let quantity = 0;
    //             let productPrice = 0;

    //             for (let i = 0; i < addKeyValues.length; i++) {
    //                 const productSubSelection = matrixRow[productName][addKeyValues[i]];

    //                 if (addKeyValues[i] === 'Quantity') { // Special exception
    //                     quantity = parseFloat(matrixRow[productName][addKeyValues[i]]);
    //                 } else {
    //                     productPrice = this.PriceLookup[productName][addKeyValues[i]][productSubSelection]; // Product sub-selection price (like small, medium, large)
    //                     console.log(addKeyValues[i])
    //                 }

    //                 console.log(addKeyValues);

    //             }

    //             this.SelectedProductsServices[productName] = quantity * productPrice;

    //         } else {
    //             console.log('Product does not have a price set');
    //             return false;
    //         }

    //     });

    // }



    // TODO - NOT CALCULATING IF YOU SELECT CREMATION TYPE BEFORE PET WEIGHT
    public CalculateSelectedProducts($event) {
        // if ($event.options.question.getType() !== 'matrixdropdown') {

        // Regular - though test checkbox...? or not needed now with matrix?
        /* ======================================================
        Correct the total price for a multi-select option
        This updated total (and others) will be calculated below for TotalPrice
        ====================================================== */
        this.OrderPricingData[$event.options.name] = []; // Reset

        if (Array.isArray($event.options.value)) {
            /* ======================================================
            Single price items like keepsakes and memorial items
            ====================================================== */
            this.SelectedProductsServices[$event.options.name] = 0; // Reset
            let totalOptionPrice = 0;
            for (let i = 0; i < $event.options.value.length; i++) {
                const currentItem = $event.options.value[i];
                const currentItemPrice = this.PriceLookup[$event.options.name][$event.options.value[i]];
                totalOptionPrice += parseFloat(currentItemPrice);
                this.OrderPricingData[$event.options.name].push({ item: currentItem, price: currentItemPrice });
            }

            this.SelectedProductsServices[$event.options.name] = totalOptionPrice;
        } else if (this.PriceLookup[$event.options.name] && this.PriceLookup[$event.options.name][$event.options.value]) {
            /* ======================================================
            Weight prices like the type of cremation and urn
            ====================================================== */

            const selectedProductPricesByWeight = this.PriceLookup[$event.options.name][$event.options.value].weightPrices;

            if (selectedProductPricesByWeight) {
                const petWeight = parseFloat(this.ClientAnswers['pets_weight']);

                for (let i = 0; i < selectedProductPricesByWeight.length; i++) {
                    let currentPriceParameters = selectedProductPricesByWeight[i];

                    if (petWeight >= currentPriceParameters.minWeight && petWeight <= currentPriceParameters.maxWeight)
                        this.SelectedProductsServices[$event.options.name] = currentPriceParameters.price; // this.PriceLookup[$event.options.name][$event.options.value].weightPrices[i].price
                }

                this.OrderPricingData[$event.options.name].push({ item: $event.options.value, price: this.SelectedProductsServices[$event.options.name] });

            } else {
                // this.SelectedProductsServices[$event.options.name]
                // // Like Memorial Items and Keepsakes / Jewelry?
                // this.SelectedProductsServices[$event.options.name] = this.PriceLookup[$event.options.name][$event.options.value];
            }

        }

        // } else {
        //     // this.CalculateMatrixRowTotal($event.options.value, ['Size', 'Quantity']);
        // }
    }



    public CalculateTotal() {
        this.Subtotal = 0; // Start over

        /* ======================================================
        Update / re-evaluate the cremation price
        ====================================================== */
        // if (
        //     this.ClientAnswers['type_of_cremation']
        //     && this.ClientAnswers['type_of_cremation'] === 'Semi-Private'
        //     && this.ClientAnswers['pets_weight']
        //     && parseFloat(this.ClientAnswers['pets_weight']) < 3
        // )
        //     this.SelectedProductsServices['type_of_cremation'] = 85;

        Object.keys(this.SelectedProductsServices).forEach((key) => {
            if (!isNaN(parseFloat(this.SelectedProductsServices[key])))
                this.Subtotal += parseFloat(this.SelectedProductsServices[key]);
        });

        // console.log(this.SelectedProductsServices)

        if (!isNaN(this.Subtotal)) {
            this.TotalTax = parseFloat((this.Subtotal * 0.08).toFixed(2)); // The method toFixed() converts a number to a string. You have to parse the variables to numbers:
            this.TotalPriceWithTax = parseFloat((this.Subtotal * 1.08).toFixed(2)); // Tax
        }

        // console.log('Total price with tax', this.Subtotal, this.TotalPriceWithTax);
    }



    public MicroSurveyEvents($event) {

        // console.log($event);

        if ($event.survey && $event.survey.data) // Won't have any property when initializing
            this.ClientAnswers = $event.survey.data;

        if ($event.eventName === 'ModelCreated') {

            setTimeout(() => {
                for (let p = 0; p < this.SurveyJSON.pages.length; p++) {

                    for (let e = 0; e < this.SurveyJSON.pages[p].elements.length; e++) {
                        const question = this.SurveyJSON.pages[p].elements[e];
                        if (question.defaultValue)
                            $event.model.setValue(question.name, question.defaultValue); // Hard set this - not working out of the box? Maybe because of pretty checkboxes, but I'm not sure...
                    }
                }
            })
        }

        if ($event.eventName === 'onComplete') {
            this.Finished = true; // Evaluate here (if items selected)
            // console.log($event.survey.data)
            let updatedData: any = this.UtilitiesService.cloneObject($event.survey.data);
            updatedData.completed = true;
            updatedData.current_pricing = this.PriceLookup;
            updatedData.current_pricing.tax = this.TotalTax;
            updatedData.current_pricing.subtotal = this.Subtotal;
            updatedData.current_pricing.totalPriceWithTax = this.TotalPriceWithTax;
            updatedData.order_pricing_data = this.OrderPricingData;
            this.SurveyService.Survey.Save(updatedData).subscribe((response: any) => {
                // console.log(response);
                this.QuestionnaireID = response._id;
            });
        }


        if ($event.eventName === 'onCurrentPageChanged') {
            // $event.model.setValue('type_of_cremation', 'Semi-Private')

            // document.querySelector('input[name="type_of_cremation_sq_104"][value="Semi-Private"]').click()

            /* ======================================================
            To save as you page, I need an identifier, so I'd need
            to ask for the pet parent info on the first page and
            use the email address as the identifier for upsert
            ====================================================== */
            // this.SurveyService.Survey.Save($event.survey.data).subscribe(response => {
            //     console.log(response);
            // });

            // Doing form after completion now
            // if (($event.survey.currentPageNo + 1) === $event.survey.PageCount) // Last page
            //     document.getElementById('surveysummary').innerHTML = document.getElementById('summarytemp').innerHTML; // Grab the dynamic text and put it in the position we want it

            this.CalculateSelectedProducts($event);

            this.AddPricesToData($event.survey.currentPageNo);
        }

        if ($event.eventName === 'onValueChanged') {

            /* ======================================================
            In the event the client has added the pet weight, let's dynamically
            update the cremation pricing
            ====================================================== */
            if ($event.survey.currentPageNo === 0 && this.ClientAnswers.pets_weight)
                this.AddPricesToData(0);

            if ($event.options && $event.options.name) {

                this.CalculateSelectedProducts($event);

                this.CalculateTotal(); // Either regular or matrix

            }

        }

        // if ($event.eventName === 'onMatrixCellValueChanged') {
        // I couldn't find how to get the product name, like 'Classic Pet'...I could only find "memorial_items"
        // this.CalculateMatrixRowTotal($event.options.rowValue, ['Size', 'Quantity']);
        // }

    }



    public PriceLookup = {

        // Order that questions are asked

        type_of_cremation: {
            Communal: {
                weightPrices: [
                    {
                        maxWeight: 2,
                        minWeight: 0,
                        price: 55,
                    },
                    {
                        maxWeight: 35,
                        minWeight: 3,
                        price: 65,
                    },
                    {
                        maxWeight: 200,
                        minWeight: 36,
                        price: 105,
                    },
                ]
            },
            'Semi-Private': {
                weightPrices: [
                    {
                        maxWeight: 2,
                        minWeight: 0,
                        price: 85,
                    },
                    {
                        maxWeight: 35,
                        minWeight: 3,
                        price: 165,
                    },
                    {
                        maxWeight: 200,
                        minWeight: 36,
                        price: 215,
                    },
                ]
            },
            Private:
            {
                weightPrices: [
                    {
                        maxWeight: 2,
                        minWeight: 0,
                        price: 195,
                    },
                    {
                        maxWeight: 35,
                        minWeight: 3,
                        price: 295,
                    },
                    {
                        maxWeight: 200,
                        minWeight: 36,
                        price: 395,
                    },
                ]
            },
        },

        chosen_urn: {
            'Black Photo Box': {
                weightPrices: [
                    {
                        maxWeight: 15,
                        minWeight: 0,
                        price: 45,
                    },
                    {
                        maxWeight: 30,
                        minWeight: 16,
                        price: 65,
                    },
                    {
                        maxWeight: 75,
                        minWeight: 31,
                        price: 85,
                    },
                    {
                        maxWeight: 180,
                        minWeight: 76,
                        price: 95,
                    },
                    {
                        maxWeight: 999,
                        minWeight: 61,
                        price: 'Not available for pets over the weight of ',
                    },
                ]
            },
            'Ceramic - Blue': {
                weightPrices: [
                    {
                        maxWeight: 15,
                        minWeight: 0,
                        price: 55,
                    },
                    {
                        maxWeight: 30,
                        minWeight: 16,
                        price: 70,
                    },
                    {
                        maxWeight: 60,
                        minWeight: 31,
                        price: 85,
                    },
                    {
                        maxWeight: 999,
                        minWeight: 61,
                        price: 'Not available for pets over the weight of ',
                    },
                ]
            },
            'Classic Paw - Slate': {
                weightPrices: [
                    {
                        maxWeight: 15,
                        minWeight: 0,
                        price: 60,
                    },
                    {
                        maxWeight: 30,
                        minWeight: 16,
                        price: 65,
                    },
                    {
                        maxWeight: 75,
                        minWeight: 31,
                        price: 90,
                    },
                    {
                        maxWeight: 200,
                        minWeight: 76,
                        price: 135,
                    },
                    {
                        maxWeight: 999,
                        minWeight: 201,
                        price: 'Not available for pets over the weight of ',
                    },
                ]
            },
            'Laser Engraved Photo Urn': {
                weightPrices: [
                    {
                        maxWeight: 200,
                        minWeight: 0,
                        price: 95,
                    },
                    {
                        maxWeight: 999,
                        minWeight: 201,
                        price: 'Not available for pets over the weight of ',
                    },
                ]
            },
            'Maus - Granite': {
                weightPrices: [
                    {
                        maxWeight: 30,
                        minWeight: 0,
                        price: 55,
                    },
                    {
                        maxWeight: 70,
                        minWeight: 31,
                        price: 60,
                    },
                    {
                        maxWeight: 180,
                        minWeight: 71,
                        price: 130,
                    },
                    {
                        maxWeight: 999,
                        minWeight: 181,
                        price: 'Not available for pets over the weight of ',
                    },
                ]
            },
            'Odyssey - Crimson': {
                weightPrices: [
                    {
                        maxWeight: 15,
                        minWeight: 0,
                        price: 65,
                    },
                    {
                        maxWeight: 30,
                        minWeight: 16,
                        price: 75,
                    },
                    {
                        maxWeight: 60,
                        minWeight: 31,
                        price: 90,
                    },
                    {
                        maxWeight: 999,
                        minWeight: 61,
                        price: 'Not available for pets over the weight of ',
                    },
                ]
            },
            'Odyssey - Pearl': {
                weightPrices: [
                    {
                        maxWeight: 15,
                        minWeight: 0,
                        price: 65,
                    },
                    {
                        maxWeight: 30,
                        minWeight: 16,
                        price: 75,
                    },
                    {
                        maxWeight: 60,
                        minWeight: 31,
                        price: 90,
                    },
                    {
                        maxWeight: 999,
                        minWeight: 61,
                        price: 'Not available for pets over the weight of ',
                    },
                ]
            },
            'Photo Cube - Navy': {
                weightPrices: [
                    {
                        maxWeight: 15,
                        minWeight: 0,
                        price: 45,
                    },
                    {
                        maxWeight: 35,
                        minWeight: 16,
                        price: 65,
                    },
                    {
                        maxWeight: 75,
                        minWeight: 36,
                        price: 85,
                    },
                    {
                        maxWeight: 999,
                        minWeight: 76,
                        price: 'Not available for pets over the weight of ',
                    },
                ]
            },
            'Photo Urn - Black': {
                weightPrices: [
                    {
                        maxWeight: 15,
                        minWeight: 0,
                        price: 45,
                    },
                    {
                        maxWeight: 30,
                        minWeight: 16,
                        price: 65,
                    },
                    {
                        maxWeight: 75,
                        minWeight: 31,
                        price: 85,
                    },
                    {
                        maxWeight: 180,
                        minWeight: 76,
                        price: 95,
                    },
                    {
                        maxWeight: 999,
                        minWeight: 181,
                        price: 'Not available for pets over the weight of ',
                    },
                ]
            },
            'Standard (included)': {
                weightPrices: [
                    {
                        maxWeight: 200,
                        minWeight: 0,
                        price: 0,
                    },
                    {
                        maxWeight: 999,
                        minWeight: 201,
                        price: 'Not available for pets over the weight of ',
                    },
                ]
            },
        },

        memorial_items: {
            'Clay Paw Print': 26.50,
            'Fur Clipping': 10,
            'Rainbow Bridge Candle': 25,
        },

        keepsake_options: {
            'No Keepsakes': 0,
            'Onyx Paw Print Cylinder': 85,
            'Stainless Steel Paw Bracelet with Paw Charm': 100,
            'Stainless Steel Paw Print Dog Tag': 135,
            'Stainless Steel Paw Prints Keychain': 50,
            'Stainless Steel Pewter Hinged Paw Pendant': 110,
            'Sterling Silver Paw Print Heart Pendant': 190,
        },

        // final_goodbye: {
        //     No: 0,
        //     Yes: 50,
        // },

        // create_memorial: {
        //     No: 0,
        //     Yes: 500,
        // },

        // 'Classic Pet': {

        //     Size: {
        //         Small: 1,
        //         Medium: 2,
        //         Large: 3,
        //     },

        // },

    };



    public StripeEvents($event) {
        if ($event && $event.paymentSuccess) {
            this.ShowStripe = !$event.paymentSuccess;
            this.PaymentSuccess = $event.paymentSuccess;
        }
    }



    ngOnInit(): void {
        this.LoadingSurvey = true;

        this.SurveyService.PrePlanSurvey.GetJSON().subscribe(response => {
            this.SurveyJSON = response.JSON;
            this.LoadingSurvey = false;
        });
    }



}
