import { Router } from '@angular/router';
import { Component } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { AuthenticationService } from '@app/core';

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  constructor(
    public router: Router,
    private authenticationService: AuthenticationService,
    private ToastrService: ToastrService
  ) {}

  public logout() {
    this.authenticationService.logout().subscribe(response => {
      this.router.navigate(['/login']);
      this.ToastrService.success('Successfully logged out');
    });
  }
}
