import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-loading',
  template: `
        <div class="loader" *ngIf="loading">
            <svg class="circular" viewBox="25 25 50 50">
                <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="4" stroke-miterlimit="10" [style.stroke]="ColorOverride" />
            </svg>
        </div>
        <!-- /.loader -->
    `,
    styleUrls: ['./button-loading.scss'],
})
export class ButtonLoadingComponent {
    
    // https://stackoverflow.com/questions/48410996/dynamically-append-component-to-div-in-angular-5
    // https://stackoverflow.com/questions/40060498/angular-2-1-0-create-child-component-on-the-fly-dynamically/40080290#40080290
    _ColorOverride: string = '';

    @Input() loading: boolean;
    @Input() ColorOverride: string = '';

    constructor() { }
    
    
    
}