<div class="stripe-wrapper my-3 px-3 pb-4 pt-3 bg-primary card shadow2">
    <h5 class="text-white">Credit Card Details</h5>
    <div id="card-errors" class="mb-3 text-danger"></div>

    <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions" (change)="onChange($event)">
    </ngx-stripe-card>

    <ng-content></ng-content>

    <button class="btn btn-info mt-2" type="submit" (click)="createToken()" [appButtonLoading]="loading">Submit
        Payment</button>
</div>