import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@app/shared';
import { SurveyRoutingModule } from './survey-routing.module';

import { SurveyComponent } from './survey/survey.component';
import { PageSurveyComponent } from './page-survey/page-survey.component';



@NgModule({
    declarations: [
        SurveyComponent,
        PageSurveyComponent,
    ],
    imports: [
        CommonModule,

        SharedModule,
        SurveyRoutingModule,
    ],
    exports: [
        SurveyRoutingModule,
    ]
})
export class SurveyModule { }
