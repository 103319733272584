import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-analytic',
  templateUrl: './analytic.component.html',
  styleUrls: ['./analytic.component.css']
})
export class AnalyticComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
