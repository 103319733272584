import { Observable } from 'rxjs';
import { Injectable, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { StripePayButtonComponent } from './../stripe/stripe-pay-button/stripe-pay-button.component';

@Injectable({
    providedIn: 'root'
})
export class DynamicComponentService {



    private classMappings = {
        StripePayButtonComponent: StripePayButtonComponent,
    };



    constructor(
        private ComponentFactoryResolver: ComponentFactoryResolver,
    ) { }



    public AddDynamicComponentToViewContainerRef(TemporaryViewContainerRef: ViewContainerRef, Element: Element) {
        const ComponentClass = Element.getAttribute('id');
        const Options = JSON.parse(Element.getAttribute('data-options'));

        if (TemporaryViewContainerRef !== null) {
            const componentFactory = this.ComponentFactoryResolver.resolveComponentFactory(this.classMappings[ComponentClass]);
            const componentRef = TemporaryViewContainerRef.createComponent(componentFactory);
            document.querySelector('#' + ComponentClass).appendChild(componentRef.location.nativeElement); // If you don't do this, then the component just stays in the TemporaryViewContainerRef element

            if (Options)
                for (let key in Options)
                    componentRef.instance[key] = Options[key];

            return componentRef;
        }

    }



}
