declare const gtag: any;
declare const _paq: any;

import { Injectable } from '@angular/core';

// import { ServerService } from './../../_services/server.service';

@Injectable({
    providedIn: 'root'
})
export class AnalyticService {



    constructor(
        // private ServerService: ServerService,
    ) { }



    public process(input: string): boolean {

        var action, category, extractedInput, label, value;

        if (input.indexOf('-') !== -1) {
            extractedInput = input.split('-');
            action = extractedInput[0];
            category = extractedInput[1] || '';
            label = extractedInput[2] || '';
            value = extractedInput[3] || '';
        }

        this.send(action, category, label, value);

        return true;

    }



    public sendPageviewToGoogle(): void {
        //     gtag.pageview();

        // // or with custom params

        //     gtag.pageview({
        //       page_title: 'Lesson Feed',
        //       page_path: '/lessons',
        //       page_location: 'https://angularfirebase.com/lessons'
        //     });
    }



    public send(action: string, category: string, label?: string, value?: string): void {
        // https://developers.google.com/analytics/devguides/collection/gtagjs/events

        // Because this is a service, I can programmatically call this OR use the appAnalytics directive

        // Due to the way the the data shows in GA and the
        // way I want to use the appAnalytic tag...switch action and category
        // appAnalytic="CallAdvisor-{{ ( !user ) ? 'NotLoggedIn' : 'LoggedIn' }}-{{ ( user && user.UserCSCEntityID ) ? user.UserCSCEntityID : '' }}"
        // OR easier,

        // gtag('event', action, {
        // gtag('event', category, {
        //   // event_category: category,
        //   event_category: action,
        //   event_label: label,
        //   // value: value // is a non-negative integer that will appear as the event value.
        // });

        // Can't have empty values, so we have to create an array like this to send
        let sendArray = ['trackEvent', action, category];
        if (label)
            sendArray.push(label);
        if (value)
            sendArray.push(value);

        _paq.push(sendArray);

        // Is this stressing the server too much?
        // this.ServerService.post('/general/addAnalytic', { ActivityType: action, Data1: category, Data2: label }).subscribe();
    }



    public sendToYosemite(): void {

    }



}



