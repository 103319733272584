import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GeneralService {



    constructor(
        private HttpClient: HttpClient,
    ) { }



    public checkVersion(version): Observable<any> {
        return this.HttpClient.get('/general/checkVersion', { params: { version: version } });
    }



}
