import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from '@app/shared';

import { PageSurveyComponent } from './page-survey/page-survey.component';

const routes: Routes = [
    {
        path: '', // 'preplan',
        component: FullComponent,
        children: [
            {
                path: '',
                component: PageSurveyComponent,
                outlet: 'LayoutRouterOutlet',
                // loadChildren: () =>
                //   import('./authentication/authentication.module').then(
                //     m => m.AuthenticationModule
                //   )
            },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SurveyRoutingModule { }