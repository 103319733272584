import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxStripeModule } from 'ngx-stripe';

import { ButtonLoadingModule } from './../modules/button-loading/button-loading.module';

import { StripePayButtonComponent } from './stripe-pay-button/stripe-pay-button.component';



@NgModule({
    declarations: [
        StripePayButtonComponent,
    ],
    imports: [
        CommonModule,

        NgxStripeModule.forRoot('pk_live_51Hjn8wA7zjkr2lYuxvBX5HIRmQLLUsyxbnMXKfwbtvGeOI0lKb3CSp1Y4nZ1ejXHCzsVRe0J6T5y0tTDixFS6Xky00NQf0C7NA'), // TJNevis@gmail.com - pk_test_yaZD3rZ1J0Hg9LFlOmPlbFlQ  /. rick@harrisfuneralhome.com - pk_test_51Hjn8wA7zjkr2lYudyP3KGzimCDYR5U7t0w570olFxX5Lh1MvJT8Q68573X8oQ0B17WTnTV8HnlKomSutuZL9sAL00oolHDKg9 - Also in server/app/controllers/payment.js

        ButtonLoadingModule,
    ],
    exports: [
        StripePayButtonComponent,
    ],
})
export class StripeModule { }
