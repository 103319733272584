<section id="wrapper" class="login-register login-sidebar">
  <div class="login-box card">
    <div class="card-body">
      <form [formGroup]="signupForm" (ngSubmit)="signup()" class="form-horizontal form-material" id="loginform">
        <a href="javascript:void(0)" class="text-center db logo-container">
        </a>
        <h3 class="box-title m-t-40 m-b-0">Register Now</h3>
        <small>Create your account and enjoy</small>
        <div class="form-group m-t-20">
          <div class="col-xs-12">
            <app-form-error-wrapper [control]="signupForm.controls['firstName']" [controlName]="'First Name'" [apiErrorType]="'firstName'">
              <input class="form-control" type="text" formControlName="firstName" placeholder="First Name">
            </app-form-error-wrapper>
          </div>
        </div>
        <div class="form-group ">
          <div class="col-xs-12">
            <app-form-error-wrapper [control]="signupForm.controls['lastName']" [controlName]="'Last Name'" [apiErrorType]="'lastName'">
              <input class="form-control" type="text" formControlName="lastName" placeholder="Last Name">
            </app-form-error-wrapper>
          </div>
        </div>
        <div class="form-group ">
          <div class="col-xs-12">
            <app-form-error-wrapper [control]="signupForm.controls['email']" [controlName]="'Email'" [apiErrorType]="'email'">
              <input class="form-control" type="text" formControlName="email" placeholder="Email">
            </app-form-error-wrapper>
          </div>
        </div>
        <div class="form-group ">
          <div class="col-xs-12">
            <app-form-error-wrapper [control]="signupForm.controls['phone']" [controlName]="'Phone'" [apiErrorType]="'phone'">
              <input class="form-control" type="text" formControlName="phone" placeholder="Phone">
            </app-form-error-wrapper>
          </div>
        </div>
        <div class="form-group ">
          <div class="col-xs-12">
            <app-form-error-wrapper [control]="signupForm.controls['password']" [controlName]="'Password'" [apiErrorType]="'password'">
              <input class="form-control" type="password" formControlName="password" placeholder="Password">
            </app-form-error-wrapper>
          </div>
        </div>
        <div class="form-group">
          <div class="col-xs-12">
            <app-form-error-wrapper [control]="signupForm.controls['cPassword']" [controlName]="'Confirm Password'" [apiErrorType]="'cPassword'">
              <input class="form-control" type="password" formControlName="cPassword" placeholder="Confirm Password">
            </app-form-error-wrapper>
          </div>
        </div>
        <div class="form-group">
          <div class="col-md-12">
            <div class="custom-control custom-checkbox p-t-0">
              <input id="checkbox-signup" class="custom-control-input" type="checkbox">
              <label for="checkbox-signup" class="custom-control-label"> I agree to all
                <a>Terms</a>
              </label>
            </div>
          </div>
        </div>
        <div class="form-group text-center m-t-20">
          <div class="col-xs-12">
            <button class="btn btn-info btn-lg btn-block btn-rounded text-uppercase waves-effect waves-light" [disabled]="signupForm.invalid || isLoading">
              <app-button-loader [isLoading]="isLoading" [label]="'Sign Up'"></app-button-loader>
            </button>
          </div>
        </div>
        <div class="form-group m-b-0">
          <div class="col-sm-12 text-center">
            <p>Already have an account?
              <a class="text-info m-l-5" [routerLink]="['/login']">
                <b>Sign In</b>
              </a>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>