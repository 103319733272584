import { ActivatedRoute, Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, RoutesRecognized } from '@angular/router';
import { Component } from '@angular/core';

import { GeneralService } from './_services/general.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'NevTec New Framework';



    constructor(
        private Router: Router,
        private GeneralService: GeneralService,
    ) { }



    private CheckVersion() {
        this.GeneralService.checkVersion('1.1.1').subscribe(response => {
            let VersionRefreshCount = parseInt(localStorage.getItem('VersionRefreshCount')) || 0;

            VersionRefreshCount = VersionRefreshCount += 1;

            if (response.refresh && VersionRefreshCount < 3) {
                localStorage.setItem('VersionRefreshCount', VersionRefreshCount.toString());

                setTimeout(() => {
                    location.reload();
                }, 250);
            } else if (!response.Refresh) {
                localStorage.removeItem('VersionRefreshCount');
            }
        });
    }



    private NavigationInterceptor(e: RouterEvent) {

        if (e instanceof NavigationEnd) {
            // this.AuthService.SetLocked('false');

            // console.log('app.component navigationInterceptor', e);

            // this.user = <IUser>this.UserService.getUser();

            this.CheckVersion();

            setTimeout(() => {
                try {
                    // New browsers
                    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
                } catch (e) {
                    // Fallback for incompatible browsers
                    window.scrollTo(0, 0);
                }
            });
        }

        // if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {

        //     /* ======================================================
        //     New feature - Remember the last URL client was on, always
        //     So if they get to the /locked screen, after they log back
        //     in, they will continue where they left off
        //     ====================================================== */
        //     if (this.UserService.isLoggedIn()) {
        //         // console.log('CALLED LandingURL SET');
        //         this.UserService.LandingURL.set();
        //     }

        //     // Remove initial splash screen
        //     setTimeout(() => {
        //         const splashScreen = document.querySelector('.app-splash-screen');
        //         if (splashScreen && splashScreen.parentNode) {
        //             splashScreen['style'].opacity = 0;
        //             splashScreen.parentNode.removeChild(splashScreen);
        //         }
        //     }, 100);

        // }

    }



    ngOnInit() {
        this.Router.events.subscribe(this.NavigationInterceptor.bind(this));
    }



}