import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewContainerRef, ComponentFactoryResolver, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { formatCurrency } from '@angular/common';

import * as $ from 'jquery';
import * as Showdown from 'showdown';
import * as Survey from 'survey-angular';
import * as widgets from 'surveyjs-widgets';
import { ToastrService } from 'ngx-toastr';

import { AnalyticService } from './../../_directives/analytic/analytic.service';
import { DynamicComponentService } from './../../_services/dynamic-component.service';
import { SurveyService } from './../survey.service';
// import { UserService } from './../../../_auth/user.service';
import { UtilitiesService } from './../../_services/utilities.service';

/* ======================================================
TODO - Implement previous and next browser or mouse button navigation
localstorage and/or hash
https://github.com/surveyjs/survey-library/issues/751
====================================================== */

@Component({
    selector: 'app-survey',
    templateUrl: './survey.component.html',
    styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() data;
    @Input()
    set handleCustomOnComplete(value) {
        this._handleCustomOnComplete = (value === true || value === 'true');
    }
    @Input()
    set handleCustomSave(value) {
        this._handleCustomSave = (value === true || value === 'true');
    }
    @Input() json;
    @Input()
    set popup(value) {
        this._popup = (value === true || value === 'true');
    }
    // @Input() QuestionnaireID;
    @Input() QuestionnaireName;
    @Input()
    set show(value) {

        if (this._popup) {
            this._show = value;
            this.SurveyService.Modal((value || value === 'true'));
        }

    }
    private _surveyCSS = {

        body: '',

        checkbox: {
            controlLabel: 'custom-control-label',
            itemControl: 'custom-control-input',
            label: 'custom-control custom-checkbox',
        },

        // comment: 'form-control w-100',

        completedPage: 'micro-survey-completed',

        container: '',

        footer: 'px-2 py-4',

        navigation: {
            complete: 'btn-info',
            next: 'btn-primary',
            prev: 'btn-default',
            start: 'btn-primary',
        },

        navigationButton: 'btn-questionnaire mt-2',

        page: {
            description: 'mb-3 p-1',
            // root: 'page-root',
            title: 'mt-4 mb-0 p-1',
        },

        // paneldynamic: {
        //     button: 'btn-primary btn-sm',
        // },

        progress: 'p-1',

        question: {
            header: 'mb-2',
            mainRoot: 'sv_qstn p-2 overflow-hidden text-serif', // overflow-hidden for IE
            // title: 'font-weight-bold mb-2', // Only takes effect once you've selected an option for the question - probably a bug
            headerTop: 'font-weight-bold mb-2', // Works but it's a level higher than the h5 title, so I have a CSS rule to force the classes
        },

        radiogroup: {
            controlLabel: 'custom-control-label',
            itemControl: 'custom-control-input',
            label: 'custom-control custom-radio',
        },

        // window: {
        //     body: 'px-2 pb-3 overflow-auto',
        //     header: {
        //         buttonCollapsed: 'pull-right fa fa-chevron-down',
        //         buttonExpanded: 'pull-right fa fa-chevron-up',
        //         root: 'p-2 bg-primary text-white font-weight-bold shadow1 title-click', // mb-4
        //     },
        //     root: 'survey-modal modal-content shadow3 expanded', // .expanded when *ngIf
        // },

    };
    @Input()
    set surveyCSS(input) {
        this._surveyCSS = this.UtilitiesService.DeepMerge(this._surveyCSS, input);
    }
    @Input() private showProgressBar = 'none';
    @Output() public surveyEvents = new EventEmitter();
    @ViewChild('TemporaryViewContainerRef', { read: ViewContainerRef }) public TemporaryViewContainerRef: ViewContainerRef;

    private _handleCustomOnComplete: boolean = false;
    private _handleCustomSave: boolean = false;
    private _popup: boolean = true;
    private _show: boolean = false;
    private surveyModel;
    private user;



    constructor(
        private AnalyticService: AnalyticService,
        // // private ComponentFactoryResolver: ComponentFactoryResolver,
        private DynamicComponentService: DynamicComponentService, // Issue with circular dependency
        private SurveyService: SurveyService,
        private ToastrService: ToastrService,
        // private UserService: UserService,
        private UtilitiesService: UtilitiesService,
    ) { }



    public CustomFunctions = {

        EsimatedNetWorthCalculation(EsimatedAnnualIncome) {
            return formatCurrency(EsimatedAnnualIncome, 'EN', '$') || null;
        },

        isDateLessThanCurrent(params) {
            if (params.length < 1) return false;
            var date = params[0];
            // debugger;
            if (typeof date == "string") date = Date.parse(date);
            return date <= new Date();
        },

    };



    private ProcessDynamicComponents() {
        // Process dynamic placeholders
        let DynamicComponentContainers = document.querySelectorAll('.survey-dynamic-component-container:not(.loaded)');
        if (DynamicComponentContainers) {
            DynamicComponentContainers.forEach((element, key) => {
                let componentRef = this.DynamicComponentService.AddDynamicComponentToViewContainerRef(
                    this.TemporaryViewContainerRef,
                    element
                );

                element.classList.add('loaded');
                let componentInstace: any = componentRef.instance; // To get rid of "Property 'EventListener' does not exist on type '{}'."

                if (componentInstace.EventListener) // Let's use a standard name of "EventListener" as an EventEmitter on the component we dynamically added
                    componentInstace.EventListener.subscribe(response => {
                        // console.log(element, key, response);
                    })
            });

        }
    }



    private RegisterQuestionnaireJSON(value) {

        /* ======================================================
        For some reason, with the popups (maybe non-popups too)
        this is getting called twice and 2 windows are popping up
        
        I guess that's because ngAfterViewInit and ngOnChanges is calling RegisterQuestionnaireJSON
        But it's not really noticeable (but maybe speed?) on the non-popup version
        
        For now, I've disabled calling RegisterQuestionnaireJSON in ngAfterViewInit, so I won't need this
        ====================================================== */
        // if (this.surveyModel && this._popup)
        //     return;

        /* ======================================================
        Custom function registration
        ====================================================== */
        // Works, just not in use right now
        // Survey
        //     .FunctionFactory
        //     .Instance
        //     .register('EsimatedNetWorthCalculation', this.CustomFunctions.EsimatedNetWorthCalculation);

        Survey.FunctionFactory.Instance.register('isDateLessThanCurrent', this.CustomFunctions.isDateLessThanCurrent);


        if (!this.UtilitiesService.IsEmptyObject(value)) {
            this.surveyModel = new Survey.Model(value);

            this.surveyModel.showProgressBar = this.showProgressBar;

            // Custom widget example - https://embed.plnkr.co/l6uF1U/
            this.surveyEvents.emit({ eventName: 'ModelCreated', model: this.surveyModel, surveyModel: this.surveyModel, QuestionnaireName: this.QuestionnaireName, });

            // Now that we have the JSON, use the [data]
            if (this.data) {
                this.surveyModel.data = this.data;
                this.surveyEvents.emit({ eventName: 'DataAdded', model: this.surveyModel, surveyModel: this.surveyModel, QuestionnaireName: this.QuestionnaireName, });
            }

            /* ======================================================
            Animation
            ====================================================== */
            this.surveyModel
                .onCurrentPageChanging
                .add((survey, options) => {
                    $('#surveyElement, #windowSurveyJS').css({ opacity: 0 }); // .animate({ opacity: 0 }, 50);
                });

            this.surveyModel
                .onCurrentPageChanged
                .add((survey, options) => {
                    setTimeout(() => {
                        $('#surveyElement, #windowSurveyJS').animate({ opacity: 1 }, 250);
                    }, 150)
                });

            /* ======================================================
            Regular events
            ====================================================== */
            // this.surveyModel.onAfterRenderQuestion.add((survey, options) => {
            //     // if (!options.question.popupdescription) { return; }
            //     // // Add a button;
            //     // const btn = document.createElement('button');
            //     // btn.className = 'btn btn-info btn-xs';
            //     // btn.innerHTML = 'More Info';
            //     // btn.onclick = function () {
            //     //     // showDescription(question);
            //     //     alert(options.question.popupdescription);
            //     // };
            //     // const header = options.htmlElement.querySelector('h5');
            //     // const span = document.createElement('span');
            //     // span.innerHTML = '  ';
            //     // header.appendChild(span);
            //     // header.appendChild(btn);

            //     // console.log(survey, options);
            // });

            this.surveyModel
                .onComplete
                .add((survey, options) => {
                    this.SurveyService.Modal(false);

                    this.surveyEvents.emit({ eventName: 'onComplete', model: this.surveyModel, options: options, QuestionnaireName: this.QuestionnaireName, survey: survey, });
                    // this.SurveyService.SendEvent({ eventName: 'onComplete', options: options, QuestionnaireName: this.QuestionnaireName, survey: survey, });

                    // if (!this._handleCustomOnComplete)
                    //     this.SurveyService.Survey.Save({ completed: true, insert: true, questionnaire_name: this.QuestionnaireName, survey: survey, }).subscribe(response => {
                    //         this.surveyEvents.emit({ eventName: 'onComplete', isExpanded: survey.isExpanded, options: options, Questionnaire: this.QuestionnaireName, });
                    //         this.SurveyService.SendEvent({ eventName: 'onComplete', isExpanded: survey.isExpanded, options: options, QuestionnaireName: this.QuestionnaireName, survey: survey, });
                    //         this.ToastrService.success('Successfully saved your answers', 'Success');
                    //     });

                });

            this.surveyModel
                .onCurrentPageChanged
                .add((survey, options) => {
                    // this.user = this.UserService.getUser();
                    // const UserCSCEntityID = (this.user && this.user.UserCSCEntityID) ? this.user.UserCSCEntityID.toString() : '';
                    // this.AnalyticService.send('Questionnaire', this.QuestionnaireName + UserCSCEntityID, 'Page', survey.currentPageNo.toString());

                    // const NextButton = (<HTMLInputElement>document.querySelector('.btn-questionnaire.btn-primary[value="Next"]'))
                    // if (NextButton)
                    //     NextButton.value = 'Skip'; // survey.pageNextText = 'Skip';

                    // this.ProcessDynamicComponents();

                    this.surveyEvents.emit({ eventName: 'onCurrentPageChanged', model: this.surveyModel, options: options, QuestionnaireName: this.QuestionnaireName, survey: survey });
                });

            this.surveyModel
                .onCurrentPageChanging
                .add((survey, options) => {
                    // let tempData = this.UtilitiesService.RemoveNulls(survey.data);
                    // if (!this._handleCustomSave)
                    //     this.SurveyService.Survey.Save({ data: tempData, eventName: 'onCurrentPageChanging', options: options, questionnaire_name: this.QuestionnaireName, survey: survey, }).subscribe();
                    this.surveyEvents.emit({ eventName: 'onCurrentPageChanging', model: this.surveyModel, options: options, QuestionnaireName: this.QuestionnaireName, survey: survey, });
                });

            /* ======================================================
            MARKDOWN
            ====================================================== */
            var converter = new Showdown.Converter();
            this.surveyModel
                .onTextMarkdown
                .add(function (survey, options) {
                    var str = converter.makeHtml(options.text); // Convert the mardown text to html
                    str = str.substring(3); // Remove root paragraphs <p></p>
                    str = str.substring(0, str.length - 4);
                    options.html = str; // Set html
                });

            /* ======================================================
            QUESTION NUMBERING - "on", "onPage", "off"
            https://surveyjs.io/Documentation/Library/?id=surveymodel#showQuestionNumbers
            ====================================================== */
            this.surveyModel.showQuestionNumbers = 'off';

            // this.surveyModel
            //     .onStarted
            //     .add((survey) => {
            //         // console.log('onStarted')
            //     });

            // this.surveyModel
            //     .onSurveyWindowExpandedChange // TJ
            //     .add((survey, options) => {
            //         let surveyWindowRoot = document.querySelector('[data-bind="css: css.window.root"]');
            //         if (survey.isExpanded)
            //             surveyWindowRoot.classList.add('expanded');
            //         else
            //             surveyWindowRoot.classList.remove('expanded');
            //         // Only really want .expanded (which sets top: 0) to be like the max...I want it to be lower if it can, but if it grows, I want the max to be at 0 instead of negative and it going offscreen if there are a lot of questions on that survey page

            //         if (!survey.isExpanded)
            //             survey.hide();
            //         else {
            //             setTimeout(() => {
            //                 // this.ProcessDynamicComponents();
            //             }, 500);
            //         }

            //         this.surveyEvents.emit({ eventName: 'onSurveyWindowExpandedChange', isExpanded: survey.isExpanded, options: options, QuestionnaireName: this.QuestionnaireName, survey: survey, });
            //     });

            this.surveyModel
                .onMatrixCellValueChanged
                .add((survey, options) => {
                    this.surveyEvents.emit({ eventName: 'onMatrixCellValueChanged', model: this.surveyModel, options: options, QuestionnaireName: this.QuestionnaireName, survey: survey, });
                });

            this.surveyModel
                .onValueChanged
                .add((survey, options) => {
                    this.surveyEvents.emit({ eventName: 'onValueChanged', isExpanded: survey.isExpanded, model: this.surveyModel, options: options, QuestionnaireName: this.QuestionnaireName, survey: survey, });

                    const NextButton = (<HTMLInputElement>document.querySelector('.btn-questionnaire.btn-primary[value="Skip"]'))
                    if (NextButton)
                        NextButton.value = 'Next';

                    // const surveySummary = document.querySelector('#surveysummary')
                    // if (surveySummary)
                    //     surveySummary.textContent = 'Result JSON:\n' + JSON.stringify(survey.data, null, 3);

                    // this.ProcessDynamicComponents();
                });


            /* ======================================================
            Start up
            ====================================================== */
            let SurveyObject = (this._popup) ? Survey.SurveyWindowNG : Survey.SurveyNG;
            SurveyObject.render('surveyElement', {
                css: this._surveyCSS, // Make sure to wait for passed [surveyCSS]
                isExpanded: true, // (this._popup) ? this._show : true,
                model: this.surveyModel,
            });

            // this.ProcessDynamicComponents();
        }

    }



    ngOnInit() {
        /* ======================================================
        Not really an issue - but when advisors tried to test the client logging in,
        going to /new-client would redirect them to /login because it was
        (and if the session expired? / they were locked?)
        reading the landing URL...but this wouldn't happen for an
        actual client who hasn't yet been in to Client Center
        ====================================================== */
        // this.UserService.LandingURL.reset();
    }



    ngAfterViewInit() {
        if (!this.UtilitiesService.IsEmptyObject(this.json)) {
            // this.RegisterQuestionnaireJSON(this.json);
            this.ProcessDynamicComponents();
        }
    }



    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.json && changes.json.currentValue) // It has changed from the original - like when continuing a wealth profile...let's change the display from paged to one page
            this.RegisterQuestionnaireJSON(changes.json.currentValue);
    }



}
